// extracted by mini-css-extract-plugin
export var wrapper = "category-module--wrapper--hmH4J";
export var category = "category-module--category--T3EfG";
export var header = "category-module--header--1aDxn";
export var title = "category-module--title--MYNdE";
export var toggle = "category-module--toggle--cG28b";
export var collapse = "category-module--collapse--SL+A0";
export var intro = "category-module--intro--sUeBl";
export var body = "category-module--body--eYnku";
export var winner = "category-module--winner--v0SA3";
export var nominees = "category-module--nominees--7Pm1H";
export var image = "category-module--image--FkdRl";
export var artist = "category-module--artist--eJxjI";
export var fadein = "category-module--fadein--kRIkX";