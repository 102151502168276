import * as styles from './yearSelector.module.scss';

import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from "react";
import chevron from '../../assets/images/chevron-down.svg';
import { pathTo } from '../../routes';

const YearSelector = ({ years, currentYear }) => {
  return (
    <div className={styles.selector}>
      <div>
        <h1 className={styles.title}>{currentYear}</h1>
        <div className={styles.toggle}>
          <img src={chevron} alt="" title=""/>
        </div>
      </div>
      <div className={styles.years}>
        {years.map(year => (
          <Link className={styles.year} activeClassName={styles.active} key={year} to={pathTo({__typename: 'ContentfulChronic', title: year})}>{year}</Link>
        ))}
      </div>
    </div>
  )
};

YearSelector.propTypes = {
  years: PropTypes.array,
  currentYear: PropTypes.number
}

YearSelector.defaultProps = {
  years: []
}

export default YearSelector;
