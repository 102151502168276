import * as styles from './index.module.scss'

import { graphql } from "gatsby"
import PropTypes from 'prop-types'
import React from "react"
import ChronicCategories from '../../components/chronic/categories'
import YearSelector from '../../components/chronic/yearSelector'
import Container from '../../components/container'
import DownloadsListing from '../../components/downloads/listing'
import Layout from '../../components/layout/index'
import PageHeader from '../../components/page/header'
import SectionsRenderer from '../../components/sections/renderer'
import Seo from '../../components/seo'
import TextRenderer from '../../components/text/renderer'

const pageHeaderHeadline = `
__Deutscher Musikautor*innenpreis:__
Throwback
`

const Chronic = ({ data }) => {
  const chronic = data.contentfulChronic
  const { year, sections, backgroundImage, teaser, showLegacyDropdown } = chronic
  const years = data.years.edges.map(node => node.node.title)
  const title = `Chronik ${chronic.title}`

  return (
    <>
      <Seo
        title={title}
      />
      <Layout hasImageHeader={!!backgroundImage}>
        <h1 className={styles.title}>{title}</h1>
        <PageHeader
          headline={pageHeaderHeadline}
          backgroundImage={backgroundImage}
          hasDivider={false}
        />
        <div className={styles.infos}>
          <Container>
            <div className={styles.teaser}>
              <YearSelector years={years} currentYear={year.year} />
              {teaser?.teaser && <TextRenderer text={teaser.teaser} />}
            </div>
          </Container>
        </div>
        {(sections && sections.length > 0 &&
          <SectionsRenderer sections={sections} />
        )}
        {showLegacyDropdown !== false &&
          <Container>
            <div className={styles.wrapper}>
              <div className={styles.row}>
                <div className={styles.main}>
                  <ChronicCategories categories={year.categories || undefined} />
                </div>
                <div className={styles.sidebar}>
                  <DownloadsListing downloads={chronic.downloads} />
                </div>
              </div>
            </div>
          </Container>
        }
      </Layout>
    </>
  )
}

Chronic.propTypes = {
  pageContext: PropTypes.shape({
    title: PropTypes.string,
    slug: PropTypes.string
  })
}

export default Chronic


export const query = graphql`
  query ChronicQuery($title: Date!) {
    contentfulChronic(title: { eq: $title}) {
      title
      sharingTitle
      sharingDescription {
        sharingDescription
      }
      teaser {
        teaser
      }
      backgroundImage: sharingImage {
        file {
          url
        }
      }
      metaTitle
      metaDescription {
        metaDescription
      }
      metaKeywords
      showLegacyDropdown
      year {
        year
        categories: category {
          id
          title
          nominees {
            id
            lastName
            firstName
            members {
              id
              lastName
              firstName
            }
            visual {
              gatsbyImageData(
                aspectRatio: 1.4953271028
                cropFocus: CENTER
                resizingBehavior: THUMB
                placeholder: DOMINANT_COLOR
                quality: 75
                width: 555
              )
            }
          }
          winner {
            id
            lastName
            firstName
            members {
              id
              lastName
              firstName
            }
            visual {
              gatsbyImageData(
                aspectRatio: 1.4953271028
                cropFocus: CENTER
                resizingBehavior: THUMB
                placeholder: DOMINANT_COLOR
                quality: 75
                width: 555
              )
            }
          }
          judges: judge {
            id
            firstname
            lastname
          }
        }
      }
      downloads {
        id
        url
        download {
          file {
            url
          }
        }
        title
      }
      sections {
        __typename
        ...Gallery
        ...Gateway
        ...Text
        ...YouTubePlayer
      }
    }

    years: allContentfulChronic(filter: {year: {current: {eq: false}}, title: {ne: "2000"}}, sort: {order: DESC, fields: title}) {
      edges {
        node {
          __typename
          title
        }
      }
    }
  }
`
