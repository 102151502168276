import React from "react";
import PropTypes from 'prop-types';

import ChronicCategory from './category';

import * as styles from './categories.module.scss';

const ChronicCategories = ({ categories }) => {
  const items = categories.map(category => <ChronicCategory key={category.id} category={category}/>);

  return (
    <div className={styles.categories}>
      {items}
    </div>
  )
};

ChronicCategories.propTypes = {
  categories: PropTypes.array
}

ChronicCategories.defaultProps = {
  categories: []
}

export default ChronicCategories;
