import * as styles from './category.module.scss'

import React, { useRef, useState } from "react"

import { GatsbyImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import Toggle from '../toggle'
import { artistTitle } from '../category/artist'

const ChronicCategory = ({ category }) => {
  const { title, nominees, winner, judges } = category;
  const refBody = useRef(null);
  const [showBody, setShowBody] = useState(false);
  const [height, setHeight] = useState(0);

  const toggleBody = () => {
    if (!showBody) {
      setHeight(refBody.current.getBoundingClientRect().height);
      setShowBody(true);
    } else {
      setHeight(0);
      setShowBody(false);
    }
  }

  const showNominee = () => {
    if (nominees && nominees.length === 1 & winner?.id === nominees[0].id) {
      return false;
    }

    return nominees && nominees.length > 0;
  }

  return (
    <div className={styles.wrapper} data-show={showBody}>
      <div className={styles.category}>
        <div className={styles.header} onClick={toggleBody}>
          {( title &&
            <div className={styles.title}>{title}</div>
          )}
          <div className={styles.toggle}>
            <Toggle onClick={() => {}} active={showBody}/>
          </div>
        </div>
        <div className={styles.collapse} style={{ height: `${height}px` }} data-shown={showBody}>
          <div className={styles.body} ref={refBody}>
            {( winner && artistTitle(winner) &&
              <div className={styles.winner}>
                <div className={styles.intro}>Preisträger:</div>
                <div className={styles.artist}>
                  { winner?.visual &&
                    <div className={styles.image}>
                      <GatsbyImage image={winner?.visual?.gatsbyImageData} alt={artistTitle(winner)}/>
                    </div>
                  }
                  <div>{artistTitle(winner)}</div>
                </div>
              </div>
            )}
            {( showNominee() &&
              <div className={styles.nominees}>
                <div className={styles.intro}>Nominierte:</div>
                {( nominees.map(nominee => (
                  <div className={styles.artist}>
                    { nominee?.visual &&
                      <div className={styles.image}>
                        <GatsbyImage image={nominee?.visual?.gatsbyImageData} alt={artistTitle(nominee)}/>
                      </div>
                    }
                    <div>{artistTitle(nominee)}</div>
                  </div>
                )))}
              </div>
            )}
            {( judges && judges.length > 0 &&
              <div className={styles.nominees}>
                <span className={styles.intro}>Juror:</span> {( judges.map(judge => `${judge.firstname} ${judge.lastname}`).join(', '))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
};

ChronicCategory.propTypes = {
  category: PropTypes.shape({
    title: PropTypes.string.isRequired,
    winner: PropTypes.object.isRequired,
    nominees: PropTypes.array.isRequired,
    judes: PropTypes.array
  }).isRequired
}

ChronicCategory.defaultProps = {
  category: {
    title: 'Title',
    nominees: [],
    judges: []
  }
}

export default ChronicCategory;
